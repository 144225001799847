<template>
  <ckeditor
    v-model="model"
    :editor="editor"
    :config="editorConfig"
    :value="value"
    outlined
    dense
    class="my-6"
  ></ckeditor>
</template>

<script>
import ClassicEditor from 'ckeditor5-custom-build/build/ckeditor'

export default {
  name: 'CKEditor',
  props: {
    value: {
      type: null,
      default: () => null,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            'heading',
            '|',
            'bold',
            'italic',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'imageUpload',
            '|',
            'fontSize',
            'fontFamily',
            'fontColor',
            'fontBackgroundColor',
            '|',
            'undo',
            'redo',
            '|',
            'alignment',
          ],
          shouldNotGroupWhenFull: true,
        },
        fontSize: {
          options: [8, 9, 10, 11, 12, 'default', 16, 18, 20, 22, 24, 26, 28],
        },
        ckfinder: {
          // Upload the images to the server using the CKFinder Quic  kUpload command.
          uploadUrl: `${process.env.VUE_APP_API_BASE_URL}/api/v1/news/image`,
        },
      },
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
<style>
</style>
